import React from "react"
import { Link } from "gatsby"

const Education = ({ data }) => {
  const { education } = data
  return (
    <div className="education_training_section_wrapper">
    <div className="education_training_section">
      <div className="container">
        <div className="education_training_block">
          {education.map(education =>
            education.url.startsWith("http") ? (
              <a
                href={education.url}
                rel="noreferrer"
                target="_blank"
                key={education.id}
                className="home-media-block-wrapper"
              >
                <img
                  src={education.image.file?.url}
                  alt="education"
                  className="home-media-block"
                />
              </a>
            ) : (
              <Link
                to={education.url}
                key={education.id}
                className="home-media-block-wrapper"
              >
                <img
                  src={education.image.file?.url}
                  alt="education"
                  className="home-media-block"
                />
              </Link>
            )
          )}
          {/* <span className="scroll-right mobileOnly">Scroll right</span> */}
        </div>
      </div>
    </div>
    <span className="scroll-right mobileOnly">Scroll right</span>
    </div>
  )
}

export default Education

