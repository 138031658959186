import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Hero from "../components/home/hero"
import Education from "../components/home/education"
import About from "../components/home/about"
import RecentNews from "../components/home/recent_news"
import RecentNewsFR from "../components/home/recent_news_fr"
import OurWork from "../components/home/our_work"
import Member from "../components/home/member"
import BookCommb from "../components/home/book"

import gsap from "gsap"
import "../../static/assets/css/anim.css"

const IndexPage = ({ data }) => {

  return (
    <Layout>
      <Seo title="Home" description="COMMB is the national not-for-profit organization for the Canadian out-of-home (OOH) industry. Our membership base is comprised of advertisers, agencies, programmatic tech-stacks and OOH companies, large and small." />
      <div className="main_content_wrapper">
        <Hero data={data.contentfulHome} />
        <div className="scroll_block" style={{ opacity: "0" }}>
          <div className="scroll_bg">
            <svg
              className="scroll_anchore"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 43.75 71.19"
            >
              <path
                d="M266,229c-3-5.36-5.32-10.68-7.86-15.87-3.45-7.09-6.92-14.15-10.64-21.11-6.24-11.68-3.76-23,5.73-30.1,12.86-9.64,32.25-1.36,34.38,14.57a24.72,24.72,0,0,1-3.09,15.48c-5.84,10.59-10.72,21.64-16.1,32.45C267.72,225.79,266.94,227.18,266,229Zm0-33.09a15.76,15.76,0,1,0-15.72-16.24A15.83,15.83,0,0,0,266,195.86Z"
                transform="translate(-244.07 -157.75)"
                fill="#fdf8f8"
              />
            </svg>
          </div>
        </div>
        <Education data={data.contentfulHome} />
        <About data={data.contentfulHome} />
        <RecentNews />
        <RecentNewsFR />
        <OurWork data={data.contentfulHome} />
        <Member data={data.contentfulHome} />
        <BookCommb data={data.contentfulHome} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Home($language: String) {
    contentfulHome(node_locale: { eq: $language }) {
      banner {
        title
        headlineL2
        titleOptional
        description {
          description
        }
        label
        url
        images {
          id
          file {
            url
          }
        }
      }

      education {
        id
        url
        image {
          file {
            url
          }
        }
        updatedAt
      }

      section3Title1
      section3Title2
      section3Description {
        section3Description
      }
      section3ImageBackground {
        gatsbyImageData
      }
      section3ImageFront {
        gatsbyImageData
      }
      section3ImageShapes {
        file {
          url
        }
      }
      section3Button {
        label
        url
      }

      section6Title
      section6Projects {
        id
        title
        description {
          raw
        }
        image {
          gatsbyImageData
        }
      }

      section7Title
      section7Description {
        section7Description
      }
      section7Label
      section7Url
      becomeMemberSmallNote

      section9Title
      section9ShortText
      bookCommb {
        id
        description {
          description
        }
        label
        url
      }
      section9Image1 {
        gatsbyImageData
      }
      section9Image3 {
        file {
          url
        }
      }
    }
  }
`